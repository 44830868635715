import {
  Button,
  Container,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { getFirestore } from "firebase/firestore";
import { app } from "../../utils/server/firebase";
import React, { useState } from "react";
import Footer from "../../assets/components/Footer";
import Navbar from "../../assets/components/Navbar";
import Seo from "../../assets/components/seo";
import { validateEmail } from "../../utils/functions";
import { toast } from "react-toastify";
import { createAccount } from "../../utils/server/auth";
import { existsNegocioEmail, saveNegocio } from "../../utils/server/negocios";

const db = getFirestore(app);

const Registro = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const giros = [
    "Agencia de viajes",
    "Agencia integradora de servicios",
    "Alimentos y bebidas",
    "Arrendadora de autos",
    "Balneario o parque acuatico",
    "Campo de golf",
    "Guardavida - salvavida",
    "Hospedaje",
    "Operadora de aventura - naturaleza",
    "Operadora de buceo",
    "Operadora de marina turistica",
    "Parque tematico",
    "Spa",
    "Tiempos compartidos",
    "Tour operador",
    " Transportadora turistica",
    "Vuelo en globo aerostatico",
    "País invitado",
    "Estado",
  ];

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      if (
        !data?.nombreContacto?.trim() ||
        !data?.descripcion?.trim() ||
        !data?.telefono?.trim() ||
        !data?.email?.trim() ||
        !data?.empresa?.trim() ||
        !data?.giro?.trim()
      ) {
        return toast.warning("Favor de llenar todos los campos");
      } else if (!validateEmail(data?.email?.trim())) {
        return toast.info("Revise que el correo electrónico sea valido");
      }
      const randomPassword = Math.random().toString(36).slice(-6);

      const account = await createAccount(data.email, randomPassword);

      if (!account) {
        return toast.error(
          "Hubo un problema al crear la cuenta, intente de nuevo."
        );
      }

      const emailAlreadyExists = await existsNegocioEmail(data.email);

      // const q = query(
      //   collection(db, "negocios"),
      //   where("email", "==", data.email)
      // );

      // const querySnapshot = await getDocs(q);

      if (emailAlreadyExists) {
        return toast.error("El email que ingresó ya se encuentra registrado");
      }

      await saveNegocio(account.uid, data);

      // const docsRef = collection(db, "negocios");
      // await addDoc(docsRef, {
      //   nombreContacto: data?.nombreContacto,
      //   telefono: data?.telefono,
      //   email: data?.email,
      //   descripcion: data?.descripcion,
      //   empresa: data?.empresa,
      //   giro: data?.giro,
      //   createAt: new Date(),
      // });

      toast.success("Registro completado con éxito");
    } catch (error) {
      console.log(error);
      switch (error.message) {
        case "FirebaseError: Firebase: Error (auth/email-already-in-use).":
          return toast.error("El email que ingresó ya se encuentra registrado");
        case "auth/invalid-email":
          return toast.error("El correo que ingresó no es válido");
        default:
          return toast.error(
            "Hubo un problema al crear tu solicitud. Recargue e intente de nuevo."
          );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      maxWidth={false}
      sx={{ padding: "0 !important", margin: "0 !important" }}
    >
      <Seo title="" />
      <Navbar />
      <Grid container justifyContent="center" marginY={12}>
        <Grid container maxWidth="sm" spacing={2} paddingX={4}>
          <Grid item xs={12}>
            <Typography variant="h6">
              Registro de compradores y expositores
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="standard"
              label="Nombre de la empresa"
              fullWidth
              required
              value={data?.empresa || ""}
              onChange={(e) => {
                setData({ ...data, empresa: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              style={{ textAlign: "left" }}
              select
              fullWidth
              label="Giro"
              color="primary"
              name="giro"
              value={data?.giro || ""}
              onChange={(e) => {
                setData({ ...data, giro: e.target.value });
              }}
            >
              <MenuItem value="" disabled selected>
                Seleccione un giro
              </MenuItem>
              {giros.map((giro, key) => (
                <MenuItem key={key} value={giro}>
                  {giro}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              label="Descripción de la empresa"
              fullWidth
              required
              value={data?.descripcion || ""}
              onChange={(e) => {
                setData({ ...data, descripcion: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              label="Nombre del contacto"
              fullWidth
              required
              value={data?.nombreContacto || ""}
              onChange={(e) => {
                setData({ ...data, nombreContacto: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              label="Cargo"
              fullWidth
              required
              value={data?.cargo || ""}
              onChange={(e) => {
                setData({ ...data, cargo: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              label="Correo electrónico"
              fullWidth
              required
              value={data?.email || ""}
              onChange={(e) => {
                setData({ ...data, email: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              label="Teléfono"
              fullWidth
              required
              value={data?.telefono || ""}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if (e.target.value == "" || regex.test(e.target.value)) {
                  setData({ ...data, telefono: e.target.value });
                }
              }}
              inputProps={{ maxLength: 10 }}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleSubmit}
              disabled={loading ? true : false}
            >
              {loading ? "Guardando registro" : "Finalizar registro"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </Container>
  );
};

export default Registro;
